import { mapActions } from 'vuex'

export default {
  name: 'jobs',
  data() {
    return {
      locationInput: '',
      keywordInput: ''
    }
  },
  computed: {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
  }
}